import React, { useState } from 'react';
import { Row, Divider } from 'antd';
import { useTitle } from "hookrouter";
import Question from '../components/Question';
import StoryModal from '../components/common/StoryModal';
import { questions } from "../data"

const DescriptionItem = ({ question, answer }) => (
  <div className="question-wrapper">
    <p className="question">{question}</p>
    <Divider />
    {answer}
  </div>
);

function Faq() {
  useTitle("F.A.Q.");
  const [visible, setVisible] = useState(false);
  const [question, setQuestion] = useState("")

  const show = (_story) => {
    setQuestion(_story);
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <div className="faq">
      <Row gutter={16} >
        {
          questions.map(s => (
            <Question onClick={() => show(s)} title={s.resume} question={s} />
          ))
        }
      </Row>
      <StoryModal
        visible={visible}
        onCancel={onClose}
      >
        <DescriptionItem question={question.question} answer={question.answer} />
      </StoryModal>
    </div>
  );
}

export default Faq;