import React from 'react';
import { Layout } from 'antd';
import Story from './pages/Story';
import Contact from './pages/Contact';
import Bio from './pages/Bio';
import Price from './pages/Price';
import Faq from './pages/Faq';
import NavBar from './components/common/NavBar'
import { useRoutes } from "hookrouter";
import "antd/dist/antd.css";
const { Header, Content, Footer } = Layout;

function App() {

  const routes = {
    "/": () => <Story />,
    "/contact": () => <Contact />,
    "/bio": () => <Bio />,
    "/faq": () => <Faq />,
    "/prices": () => <Price />,
  };
  const routeResult = useRoutes(routes);

  return (
    <Layout className="layout">
      <Header>
        <NavBar />
      </Header>
      <Content>
        {routeResult || <div>Not found Page </div>}
      </Content>
      <Footer>
        <div style={{ textAlign: 'center' }}>

        </div>
      </Footer>
    </Layout >
  );
}

export default App;

