import React from 'react';
import { Row, Col, Image } from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import { useTitle } from "hookrouter";
import { biography } from "../data"

function Bio() {
  useTitle("Bio");
  const bio = biography.biography
  const image = biography.image
  const activities = biography.activities

  return (
    <div className="bio">
      <h1>Biographie <SmileOutlined /></h1>
      <Row gutter={[16, 16]}>
        <Col className="col" md={{ span: 12 }} sm={{ span: 24 }} >

          <div className="text-margin">
            <p>{bio}</p>
            <ul>
              {
                activities.map((a, i) => (
                  <li key={i}>{a}</li>
                ))
              }
            </ul>
            <div>
              <p>
                Si vous souhaitez rétablir le calme et l’harmonie au sein de foyer ou encore établir
                une nouvelle routine avec votre enfant qui vous permettra d’y arriver,
                je suis la personne que vous cherchez. Plusieurs parents en ont
                <a href="/" className="link">
                  &nbsp;témoigné
                </a>.
                &nbsp;Plusieurs
                <a href="/prices" className="link">
                  &nbsp;forfaits
                </a> sont disponibles, selon besoins.
              </p>
            </div>
          </div>
        </Col>
        <Col className="col" md={{ span: 12 }} sm={{ span: 24 }}>
          <div className="img-div">
            <Image
              src={image}
              width={400}
            />
          </div>
        </Col>
      </Row>
    </div >
  );
}

export default Bio;
