
import sara from "./images/sarah.jpg"
import livia from "./images/famille_livia_P3Dl5XY.jpg"
import ball from "./images/gabriel-baranski-vGICUyYW2CE-unsplash.jpg"
import sophia from "./images/sophia_et_veronique_.jpg"
import two from "./images/two.jpg"
import elo from "./images/eloise_eos.jpg"

export const _stories = [
    {
        image: sara,
        resume: "Son approche marie savamment plaisir, fermeté, constance et empathie.",
        story: "Éloïse nous accompagne ponctuellement avec l'éducation de notre fille depuis plusieurs années. Son approche marie savamment plaisir, fermeté, constance et empathie. La base de son enseignement a pour fondement les principes de respect, de responsabilisation de ses comportements et d'autonomie. Elle est aussi très habile à percevoir et comprendre ce qui cloche dans des dynamiques relationnelles parents/enfants pour ensuite proposer des actions pour les transformer.\r\n\r\nNatalie-Ann mère de Sarah",
        story_id: 4,
    },
    {
        image: two,
        resume: "Toutes ces interventions nous ont permis d avoir une atmosphère plus saine et surtout une meilleure relation avec notre coco!",
        story: "J'ai souvent sollicité Eloïse lorsque je rencontrais des difficultés à imposer un cadre à la maison, elle a toujours répondu à nos demandes qui ont été très bénéfiques. Elle a compris comment il fallait gérer une crise et nous a accompagnés à maintenir ce rythme longtemps. Toutes ces interventions nous ont permis d avoir une atmosphère plus saine et surtout une meilleure relation avec notre coco!\r\nEloise a toujours pris le temps de nous expliquer comment intervenir et se faire écouter. \r\nAu besoin, il est certain que je la resolliciterai afin de nous redonner de beaux conseils!\r\n\r\nHajer mère de Adam et rayan",
        story_id: 3,
    },
    {
        image: sophia,
        resume: "Le \"terrible two\" s'est vécu tout en douceur grâce à ses judicieux conseils!",
        story: "Éloïse m'a donné de conseils précieux qui m'ont permis de mieux communiquer avec ma fille et d'éviter les crises.  Elle m'a également aidée à l'accompagner dans la gestion de ses émotions. Le \"terrible two\" s'est vécu tout en douceur grâce à ses judicieux conseils! \r\n\r\nC'est fou comment un regard externe peut nous éclairer sur nos pratiques, même quand on fait de notre mieux et qu'on pense faire tout adéquatement.\r\n\r\nVéronique mère de Sofia",
        story_id: 2,
    },
    {
        image: ball,
        resume: "Notre vie familiale est complètement transformée. Alors que nous avions tout essayé, Éloïse a su nous guider avec ses conseil",
        story: "Inestimable référence pour les parents dont les enfants font des crises ou ont du mal à s’acclimater aux situations de stress, surtout en ce temps de pandémie. Éloïse est magique. Elle nous a beaucoup aidé cet automne. Et ma plus grande à diminué ses crises de 75%. \r\n\r\nNotre vie familiale est complètement transformée. Alors que vous avions tout essayé, Éloïse a su nous guider avec ses conseils justes et professionnels. \r\n\r\nJulie, mère de Simone",
        story_id: 1,
    },
    {
        image: livia,
        resume: "Éloïse offrait un encadrement cohérent, efficace et aimant pour tous les petits défis de la vie d'un enfant en bas âge",
        story: "Eloïse a une approche dynamique et constructive, axée sur les capacités et la personnalité unique de chaque enfant, l'accompagnant dans ses différentes étapes d'apprentissage vers une autonomie adaptée à son âge. Elle a une grande capacité d'adaptation et une approche d'intervention qui repose sur la conviction du potentiel de l'enfant à comprendre ce qui lui est expliqué dans ses mots, et à pouvoir se développer pleinement à travers une relation de confiance, de constance et de respect. Notre enfant est heureuse et épanouie, en partie grâce à sa chère Éloïse! Merci Élo!!!",
        story_id: 5,
    },
]

export const biography = {
    activities: ["Activités d’apprentissage ", "Développement de l’autonomie", "Communication et intervention– langage des signes-parler sans chialer, utiliser des mots et non des cris/crises ", "Respect des consignes et des règles avec des conséquences appropriées", "Consultations téléphoniques", "Visites ponctuelles"],
    biography: "Il y a 7 ans maintenant que j’ai ouvert mon service de garde en milieu familial. Durant ces années, j’ai rencontré des enfants avec des besoins de communication et d’intervention différents, ce qui m’a permis de développer un répertoire de connaissances multifacettes. Voilà pourquoi, j’offre maintenant mes services aux familles souhaitant établir une meilleure relation avec leur enfant en proposant un programme conçu sur mesure, qui peut inclure, entre autres.",
    id: 1,
    image: elo,
    mission: "Mission",
}

export const contact = {
    contact_message: "N'hésite pas à communiquer avec moi",
    facebook: "https://www.facebook.com/El-o-secours-113131120332634/?view_public_for=1131",
    id: 1,
    instagram: null,
    label_mail: "mail",
    label_message: "message",
    label_name: "nom",
    mail: "Lavarenne.el@gmail.com",
    slogan: "Slogan",
    social_network: "El-o-secours",
    telephone: "514-975-0132",
}

export const questions = [{
    answer: "action",
    question: "Questions",
    question_id: 1,
}]

export const prices = {
    home: 150,
    id: 1,
    phone: 100,
    ponctual_home: 40,
    ponctual_phone: 30,
}