import React from 'react';
import { Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

function StoryModal({ visible, onCancel, children }) {

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={null}
      closeIcon={<CloseOutlined />}
    >
      {children}
    </Modal>
  );
}

export default StoryModal;