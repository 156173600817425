import React from 'react';
import { Row, Col, } from 'antd';
import { MailFilled, PhoneFilled, LikeFilled, InstagramFilled, FacebookFilled } from '@ant-design/icons';

function SocialNetWorkRow({ telephone, mail, facebook, instagram }) {

  return (
    <Row gutter={{ xs: 8, sm: 14, md: 20, lg: 24 }}
    >
      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
        <PhoneFilled className="icon" rotate="100" />
        <p className="title">Téléphone</p>
        <p>{telephone}</p>
      </Col>
      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
        <MailFilled className="icon" />
        <p className="title">Courriel</p>
        <p>{mail}</p>
      </Col>
      <Col classname="social-link" xs={24} sm={24} md={8} lg={8} xl={8}>
        <LikeFilled className="icon" />
        <p className="title">Réseaux sociaux</p>
        {facebook && <a href={facebook}><FacebookFilled /><p>Facebook</p></a>}
        {instagram && <a href={instagram}><InstagramFilled /><p>Instagram</p></a>}
      </Col>
    </Row>
  );
}

export default SocialNetWorkRow;

