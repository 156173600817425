import axios from 'axios';

const functionsUrl = "./.netlify/functions"

export const sendEmail = async (body) => {
    try {
        const result = await axios.post(`${functionsUrl}/sendEmail`, body)
        return result
    } catch (error) {
        console.log(error)
    }

}
