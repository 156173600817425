import React, { useState } from 'react';
import { Row } from 'antd';
import { useTitle } from "hookrouter";
import StoryCard from '../components/StoryCard';
import StoryModal from '../components/common/StoryModal';
import { _stories } from "../data"
const DescriptionItem = ({ title, content }) => (
  <div className="site-description-item-profile-wrapper">
    <h1 className="site-description-item-profile-p-label">{title}</h1>
    {content}
  </div>
);

function Story() {

  useTitle("Story");
  const [visible, setVisible] = useState(false);
  const [stories,] = useState(_stories)
  const [story, setStory] = useState("")


  const show = (_story) => {
    setStory(_story);
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <div className="story">
      <Row gutter={16} >
        {
          stories.map(s => (
            <StoryCard onClick={() => show(s)} title={s.resume} story={s} />
          ))
        }
      </Row>
      <StoryModal
        visible={visible}
        onCancel={onClose}
      >
        <DescriptionItem title={story.resume} content={story.story} />
      </StoryModal>
    </div>
  );
}

export default Story;