import React from 'react';
import { useTitle } from "hookrouter";
import SocialNetWorkRow from '../components/SocialNetWorkRow'
import ContactForm from '../components/ContactForm'
import { contact } from "../data"
function Contact() {
  useTitle("Contact");


  return (
    <div className='contact'>
      <ContactForm contact={contact} />
      <SocialNetWorkRow
        telephone={contact.telephone}
        mail={contact.mail}
        facebook={contact.facebook}
        instagram={contact.instagram}
      />
    </div >
  );
}

export default Contact;