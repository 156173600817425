import React from 'react';
import { List, Card } from 'antd';
import { useTitle } from "hookrouter";
import { HomeOutlined, PhoneOutlined, ScheduleOutlined } from '@ant-design/icons';
import { prices } from "../data"

function Price() {
  useTitle("Tarifs");

  const t1 = <div> Consultation téléphonique <PhoneOutlined rotate="100" /></div>
  const t2 = <div>Consultation à domicile<HomeOutlined />  </div>
  const t3 = <div>Consultation ponctuelle<ScheduleOutlined /> </div>
  const rdv =
    [
      '1r rendez-vous',
      '2e rendez-vous',
      '3e rendez-vous',
      '4e rendez-vous',
    ]

  const data = [
    {
      title: t1,
      id: 1,
      details: [
        '1 heure – évaluation de la problématique',
        '1 heure - présentation du plan d’action',
        '1 heure évaluation de l’évolution',
        '30 minutes : évaluation des progrès',
      ],
      tarif: [`${prices.phone.toFixed(2)} $`]
    },
    {
      title: t2,
      id: 1,

      details: [
        '30 minutes    - évaluation téléphonique',
        '3 heures    - observation de la routine',
        '1 heure    - présentation du plan d’action',
      ],
      tarif: [`${prices.home.toFixed(2)} $`]

    },
    {
      title: t3,
      id: 2,

      details: [
        'Tarif horaire – téléphonique',
        'Tarif horaire – à domicile (2 heures min)'
      ],
      ponctuel: [
        'Tarif horaire – téléphonique',
        'Tarif horaire – à domicile (2 heures min)'
      ],
      tarif: [`${prices.ponctual_phone.toFixed(2)} $`, `${prices.ponctual_home.toFixed(2)} $`],
    }
  ];
  return (
    <div className="prices">
      <h1>Liste de prix</h1>
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 1,
          lg: 3,
          xl: 3,
          xxl: 3,
        }}
        classname="card-price"
        dataSource={data}
        renderItem={item => (
          <List.Item >
            <Card title={item.title} size="default">
              {item.details.map((detail, index) =>
              (
                <div>
                  {
                    item.id === 1 ?
                      (
                        <>
                          <p className="rdv">{rdv[index]}</p>
                          <p className="detail"> {detail}</p>
                        </>
                      ) :
                      (
                        <>
                          <p className="rdv">{detail}</p>
                          <h1 className="price"> {item.tarif[index]}</h1>
                        </>
                      )
                  }
                </div>
              )
              )
              }
              <div style={{ textAlign: 'center' }}>
                {item.tarif.map(_price => (
                  <>
                    {
                      item.id === 1 ?
                        (<h1 className="price">{_price}</h1>) : ("")
                    }
                  </>
                ))}
              </div>
            </Card>
          </List.Item>
        )}
      />
    </div >
  );
}

export default Price;
