import React from 'react';
import { Image, Card, Row, Col } from 'antd';

function StoryCard({ onClick, story }) {
  return (
    <Col
      onClick={onClick} xs={24} sm={24} md={12} lg={12} xl={12}
      className="col-card"
      key={story.story_id} >
      <Card
        bordered={false}
        hoverable
        cover={
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={14} xl={12}>
              <Image className="rounded"
                src={story.image}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={10} xl={12}>
              <h2>
                {story.resume}
              </h2>
              <p className="description">
                {story.story}
              </p>
              <div>
                <p className="btn-description">
                  En savoir plus
                </p>
              </div>
            </Col>
          </Row>
        }
      >
      </Card>
    </Col>
  );
}

export default StoryCard;