import React from 'react';
import { Card, Row, Col } from 'antd';

function Question({ onClick, question }) {
  return (
    <Col onClick={onClick} xs={24} sm={24} md={12} lg={12} xl={12} className="col-card" key={question.question_id} >
      <Card
        bordered={false}
        hoverable
        cover={
          <Row gutter={16}>
            <Col>
              <p>
                {question.question}
              </p>
              <div>
                <p className="btn-description">
                  En savoir plus
                </p>
              </div>
            </Col>
          </Row>
        }
      >
      </Card>
    </Col>
  );
}

export default Question;