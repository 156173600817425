import React, { useState } from "react";
import { Menu } from 'antd';
import { HomeOutlined, AppstoreOutlined, IdcardOutlined, DollarOutlined, SmileTwoTone, HeartTwoTone, CheckCircleTwoTone, QuestionCircleOutlined } from '@ant-design/icons';

function NavBar() {
  const [current, setCurrent] = useState({})

  const handleClick = e => {
    setCurrent({ current: e.key })
    window.location.href = e.key;
  };

  return (
    <Menu className="navbar" style={{ textAlign: 'right' }} onClick={handleClick} selectedKeys={[current]} mode="horizontal">
      <Menu.Item key="#" className="logo">
        <SmileTwoTone />
        <HeartTwoTone twoToneColor="#eb2f96" />
        <CheckCircleTwoTone twoToneColor="#52c41a" />
      </Menu.Item>
      <Menu.Item key="/" >
        <div>
          <HomeOutlined />Accueil
        </div>
      </Menu.Item>
      <Menu.Item key="/bio" >
        <div>
          <AppstoreOutlined />
          Bio
        </div>
      </Menu.Item>
      <Menu.Item key="/contact">
        <div>
          <IdcardOutlined />
          Contact
        </div>
      </Menu.Item>
      <Menu.Item key="/faq">
        <div>
          <QuestionCircleOutlined />
          F.A.Q.
        </div>
      </Menu.Item>
      <Menu.Item key="/prices">
        <div>
          <DollarOutlined />
           Tarifs
        </div>
      </Menu.Item>
    </Menu>
  );
}
export default NavBar;