import React from 'react';
import { Form, Input, Button, Row, Col, notification } from 'antd';
import { WechatOutlined } from '@ant-design/icons';
import { sendEmail } from "../services/functionApiService"
function ContactForm(props) {
  const { contact_message, label_name, label_mail, label_message } = props.contact
  const [form] = Form.useForm();
  const onCheck = async () => {
    try {
      const values = await form.validateFields();
      const result = await sendEmail(values)
      openNotification(result)
    } catch (errorInfo) {
      console.log(errorInfo)
    }
  };

  const openNotification = result => {
    let type = 'Success'
    let description = 'Message envoyé'

    if (!result) {
      type = 'Erreur'
      description = "Erreur lors de l'envoie, veuillez ressayer"
    }

    notification[type]({
      message: type,
      description
    });
  };

  return (
    <Row gutter={{ xs: 8, sm: 14, md: 20, lg: 24 }}>
      <Col xs={12} sm={12} md={12} lg={12} xl={10}>
        <Form form={form} name="contact">
          <h1 className="title">{contact_message}  <WechatOutlined /></h1>
          <Form.Item
            {...formItemLayout}
            name="username"
            rules={[
              {
                required: true,
                message: 'Veuillez entrer votre nom',
              },
            ]}
          >
            <Input placeholder={label_name} />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="email"

            rules={[
              {
                required: true,
                type: "email",
                message: 'Veuillez entrer un courriel valide',
              },
            ]}
          >
            <Input placeholder={label_mail} />
          </Form.Item>
          <Form.Item  {...formItemLayout} name='message'>
            <Input.TextArea placeholder={label_message} />
          </Form.Item>
          <Form.Item {...formItemLayout}>
            <Button className="contact-button" onClick={onCheck}>
              Envoyer
              </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}

export default ContactForm;

const formItemLayout = {
  wrapperCol: { span: 12 },
};